import React from 'react';
import styled from 'styled-components';
import { ContactLink } from '../components/ContactLink';
import SEO from '../components/core/Seo';
import { Card } from '../components/general';
import Layout from '../layouts/Layout';
import InfoPage from '../templates/InfoPage';
import { breakpoint, color } from '../theme';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 4rem;
  
  @media ${breakpoint.md} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2.8%;
  }

  & > div {
    margin-bottom: 2rem;
    padding: 2rem;
    height: fit-content;

    :last-child {
      margin-bottom: 0rem;
    }

    @media ${breakpoint.md} {
      margin-bottom: 0;
    }

    h3 {
      margin-bottom: 1em;
    }
  }
`;

const Parking = styled.p`
  margin-top: 2em;
  color: ${color.brand[50]};
  font-size: 0.75em;
`

const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <InfoPage span="Contact" title="Get in touch, we're here to help" titleWidth="10" containerLarge>
        <Grid>
          <Card>
            <h3>Call / Email</h3>
            <p>07843 102275</p>
            <p>hello@knotsmassage.co.uk</p>
          </Card>
          <Card>
            <h3>Address</h3>
            <p>Knots<br />
            Crowne Plaza Royal Victoria<br />
            Victoria Station Rd<br />
            Sheffield<br />
            S4 7YE
            </p>
            <Parking>
              <b>Parking is FREE to all clients</b>. Please ensure you give the main reception your car registration details upon arrival.
            </Parking>
          </Card>
          <Card>
            <h3>Hours</h3>
            <p>Mon to Fri - 7am to 8pm</p>
            <p>Saturday - 7am to 2pm</p>
            <p>Sunday - 7am to 11am</p>
          </Card>
        </Grid>
      </InfoPage>
    </Layout>
  );
};

export default Contact;
